<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Uusi vuokralainen</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <tenant-form submit-btn-text="Luo" @submit="submit"> </tenant-form>
  </div>
</template>

<script>
import TenantForm from "../../components/Tenant/TenantForm";
import mixins from "../../mixins/mixins";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  title: "Uusi vuokralainen",

  components: {
    TenantForm,
  },

  mixins: [mixins],

  created() {
    this.setDefaultTenant(this.$store.state.account);
  },

  computed: {
    ...mapState("tenant", ["currentTenant"]),
  },

  methods: {
    ...mapMutations("tenant", ["setDefaultTenant", "setTenantField"]),
    ...mapActions("tenant", ["createTenant"]),

    async submit() {
      try {
        await this.createTenant(this.currentTenant);
        this.showPopup("Uusi vuokralainen luotu", "success");
        setTimeout(() => {
          this.$router.push("/tenants");
        }, 800);
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
